import React from 'react'
import design from '../design'
import Desktop from '../containers/desktop/Overview'
import Mobile from '../containers/mobile/Overview'

const Overview = ({ className, children, ...rest }) => {
  return typeof window !== `undefined` && window.innerWidth >= design.breakpoint.desktop ? <Desktop /> : <Mobile />
}

export default Overview
