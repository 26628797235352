import React from 'react'
import styled from 'styled-components'
import design from '../../design'
import { Link } from "gatsby"

const Overview = ({ className, children, ...rest }) => {
  return (
    <div className={className}>
      test mobile overview
    </div>
  )
}

export default styled(Overview)`
  
`
